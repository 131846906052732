module.exports = {
  defaultTitle: "Cale Crawford",
  logo: "https://calecrawford.com/favicon/favicon-512.png",
  author: "Cale Crawford",
  url: "https://calecrawford.com",
  legalName: "Cale Crawford",
  defaultDescription:
    "I’m Cale Crawford and I’m a fullstack developer with a focus on architecture!",
  socialLinks: {
    twitter: "http://www.twitter.com/calecrawford",
    github: "https://github.com/calecrawford",
    linkedin: "https://www.linkedin.com/in/ismail-ghallou-630149122/",
    instagram: "https://instagram.com/calecrawford",
    // youtube: "https://www.youtube.com/user/smakoshthegamer",
    // google: "https://plus.google.com/u/0/+IsmailSmakoshGhallou",
  },
  // googleAnalyticsID: "UA-88875900-4",
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",
  social: {
    facebook: "appId",
    twitter: "@smakosh",
  },
  address: {
    city: "City",
    region: "Region",
    country: "Country",
    zipCode: "ZipCode",
  },
  contact: {
    email: "email",
    phone: "phone number",
  },
  foundingDate: "2018",
  recaptcha_key: "6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN",
}
